.root {
  display: flex;
  margin-top: 60px;
  background: #111111;
  color: #fff;
  width: 100vw;
  padding: 24px;
  .container {
    width: 90vw;
    max-width: 960px;
    margin: 0 auto;
  }
  .logo {
    path {
      fill: #fff;
    }
  }
  hr {
    height: 1px;
    background-color: #202020;
    border: none;
    margin: 24px 0;
  }
  .poweredBy {
    text-align: center;
    font-size: 13px;
  }
  .poweredByWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .followInsta {
    width: 24px;
    height: 24px;
    background: #906DFE;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 6px;
    border-radius: 50px;
    text-transform: uppercase;
    svg {
      width: 22px;
      height: 22px;
      fill: #fff;
    }
  }
  .logoWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
  }
}