.root {
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.9);
}
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  background: #fff;
  padding: 24px;
  border: 1px solid #e5e5e5;
  border-radius: 24px;
  width: 90vw;
  max-width: 500px;
}
.formAuth {
  width: 100%;
}