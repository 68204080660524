.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  iframe {
    width: 100%;
    height: calc(100vh - 190px);
    border: 0;
  }
}
