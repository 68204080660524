.root {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  height: 100%;
}
.playerButton {
  height: 66px;
  width: 66px;
  flex: 0 0 66px;
  box-shadow: inset 0 0 0 2px #cecece;
  border-radius: 100%;
  position: relative;
  box-sizing: content-box;
  transition: .45s ease;
  &:before {
    content: "";
    position: relative;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    display: block;
    width: 15px;
    height: 15px;
  }
  &.play:before {
    background: url(../../assets/icons/play-button-arrowhead.png) center center/cover;
    left: 2px;
  }
  &.stop:before {
    background: url(../../assets/icons/stop-button.png) center center/cover;
    left: 1px;
  }
  &:after {
    content: "";
    display: block;
    box-sizing: border-box;
    width: 66px;
    height: 66px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%) scale(.73);
    border-radius: 50%;
    border: 3px solid #111;
    opacity: 0;
    transition: transform .45s cubic-bezier(.165,.84,.44,1),opacity .45s cubic-bezier(.165,.84,.44,1);
  }
  &:hover:after {
    transform: translate(-50%,-50%) scale(1);
    opacity: 1;
  }
}