.root {}

.content {
  width: 90vw;
  max-width: 800px;
  margin: 0 auto;

  img {
    border-radius: 100%;
    width: 250px;
    margin: 0 auto 32px;
    display: block;
  }

  p ~ p {
    margin-top: 24px;
  }
}
