.root {
    padding: 32px;
    width: 100%;
    display: flex;
    justify-content: center;
    @media screen and (max-width: 768px) {
        justify-content: unset;
    }
    position: relative;
}
.menuButton {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 32px;
    margin: auto;
    border-radius: 12px;
    width: 42px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    img {
        width: 24px;
        height: 24px;
    }
}