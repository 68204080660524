* {
  margin: 0;
  padding: 0;
  list-style: none;
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
}

a, button {
  cursor: pointer;
  border: 0;
  background: none;
  color: inherit;
  text-decoration: none;
}

body {
  font-family: "Lato", sans-serif;
  color: #333;
  line-height: 1.6;
}

.title-extra {
  font-weight: 700;
  font-size: clamp(32px, 6vw, 48px);
  line-height: 100%;
}

.title {
  font-weight: 400;
  font-size: 32px;
  line-height: 100%;
}

.subtitle {
  font-weight: 300;
  font-size: clamp(18px, 5vw, 24px);
  line-height: 150%;
}

.headerBorder {
  border-bottom: 1px solid #e5e5e5;
}

a[href="#auth-sign-up"] {
  font-weight: 600;
}
.supabase-auth-ui_ui-message {
  padding: 12px;
  border-radius: 8px;
  border: 2px dashed #906DFE;
  color: #906DFE;
  font-size: 14px !important;
}