.root {
  width: 100vw;
  max-width: 400px;
  height: 100vh;
  position: fixed;
  top: 0;
  right: -400px;
  background: #fff;
  box-shadow: 0 0 25px rgba(0,0,0,.08);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 32px;
  gap: 32px;
  transition: 0.5s;
  z-index: 10;
  &.open {
    right: 0;
  }
  @media screen and (min-width: 768px) {
    border-radius: 16px 0 0 16px;
  }
}
li {
  font-weight: 400;
  font-size: 24px;
  margin-top: 16px;
}
.close {
  font-weight: 700;
  font-size: 16px;
  position: absolute;
  top: 32px;
  right: 32px;
}