.root {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 24px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 16px;
  transition: 0.5s;
  width: fit-content;
  align-items: center;
  max-width: 150px;
  &:hover {
    border-color: #ccc;
    box-shadow: 0 0 8px 0 #e5e5e5;
  }
}
.audioTitle {
  font-weight: 700;
  font-size: 18px;
  line-height: 110%;
  width: 100%;
  text-align: center;
}