.root {
    background: url(../../assets/img/bg_home.jpg) no-repeat center center/cover;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 768px) {
        background: url(../../assets/img/bg_mobile.jpg) no-repeat 70% 20%/cover;
    }
}
.content {
    padding: 32px;
    margin-top: auto;
    max-width: 510px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    @media screen and (max-width: 768px) {
        color: #fff;
    }
}
.buttonWrapper {
    display: flex;
    gap: 12px;
    a {
        background: #fff;
        border-radius: 50px;
        padding: 12px 32px;
        font-size: 13px;
        text-transform: uppercase;
        font-weight: 600;
        transition: .5s;
        color: #111111;
        &:hover {
            background: #E64067;
            color: #fff;
        }
    }
}