.root {
}
.content {
  width: 100vw;
  max-width: 800px;
  margin: 0 auto ;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 16px;
}
.withOutAudio {
  $self: &;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 32px;
  margin-top: 24px;
  &_title {
    font-weight: 300;
    font-size: 24px;
    line-height: 130%;
  }
  &_button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 12px 24px;
    gap: 10px;
    background: #906DFE;
    border-radius: 8px;
    font-weight: 600;
    font-size: 18px;
    line-height: 130%;
    color: #fff;
    border: 0;
    margin-top: 16px;
  }
}
.protocolList {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.coverPage {
  background: #f8f8f8;
  padding: 42px 0;
  margin-bottom: 32px;
  text-align: center;
  h2 {
    font-size: 42px;
  }
  p {
    font-size: 18px;
  }
}
.container {
  width: 100vw;
  max-width: 800px;
  margin: 0 auto;
}