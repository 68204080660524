.root {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 32px;
  padding: 24px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 16px;
  transition: 0.5s;
  &:hover {
    border-color: #ccc;
    box-shadow: 0 0 8px 0 #e5e5e5;
  }
}
.audioTitle {
  font-weight: 700;
  font-size: 24px;
  line-height: 110%;
}
.audioDescription {
  font-weight: 300;
  font-size: 16px;
  line-height: 100%;
  margin-top: 8px;
}
.tag {
  background: #E64067;
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 4px 16px;
  gap: 10px;
  font-weight: 600;
  font-size: 13px;
  line-height: 150%;
  color: #FFFFFF;
  white-space: nowrap;
  width: fit-content;
  @media screen and (min-width: 768px) {
    margin-left: auto;
  }
}
.wrapper {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  width: 100%;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: start;
  }
}